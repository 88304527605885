import { createContext } from 'react'

const SidebarContext = createContext<{
  collapsed: boolean
  extended: boolean
  setCollapsed: (collapsed: boolean) => void
}>({
      collapsed: false,
      extended: false,
      setCollapsed: _ => _,
    })

export default SidebarContext
