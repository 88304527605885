import { cva } from 'cva'

export function getSidebarIconClassBuilder() {
  return cva(['h-5 flex-none'], {
    variants: {
      collapsed: {
        true: ``,
        false: ``,
      },
      active: {
        true: `transition-colors`,
      },
    },
    compoundVariants: [
      {
        collapsed: true,
        active: true,
        className: `transition-none`,
      },
    ],
  })
}
