import { cva } from 'cva'

export function getSidebarItemClassBuilder() {
  return cva(
    [
      `font-base flex w-full items-center rounded-md border-transparent text-sm transition-colors duration-100`,
    ],
    {
      variants: {
        collapsed: {
          true: `justify-center py-2 [&>span]:hidden`,
          false: `space-x-2.5 px-4 py-2`,
        },
        active: {
          true: `bg-primary/5 dark:bg-primary-300/10 font-medium`,
          false: `dark:hover:bg-dark-800/40 dark:active:bg-dark-700 ring-transparent hover:bg-gray-50 active:bg-gray-100 dark:text-gray-300 dark:hover:text-white`,
        },
      },
      compoundVariants: [
        {
          collapsed: true,
          active: true,
          className: `bg-primary/5 text-primary dark:bg-dark-800 dark:text-white`,
        },
        {
          collapsed: false,
          active: true,
          className: `text-primary-700 dark:bg-dark-800 dark:text-white`,
        },
        {
          collapsed: true,
          active: false,
          className: `dark:text-gray-300`,
        },
      ],
    },
  )
}
