import { useContext } from 'react'
import { NavLink } from '@remix-run/react'
import { getSidebarItemClassBuilder } from '../../utils/SidebarItem/getSidebarItemClassBuilder'
import { getSidebarIconClassBuilder } from '../../utils/SidebarItem/getSidebarIconClassBuilder'
import SidebarContext from '~/lib/contexts/sidebar'
import { Tooltip, TooltipContent, TooltipTrigger } from '~/core/ui/Tooltip'
import If from '~/core/ui/If'

interface Props {
  path: string
  Icon: React.ElementType
  end?: boolean
  children: React.ReactNode
}

export const SidebarItem: React.FC<Props> = ({ path, Icon, end, children }) => {
  const { collapsed } = useContext(SidebarContext)

  const navLink = (
    <NavLink
      end={end}
      key={path}
      to={path}
      className={({ isActive }) =>
        getSidebarItemClassBuilder()({
          collapsed,
          active: isActive,
        })}
    >
      {({ isActive }) => (
        <>
          <Icon
            className={getSidebarIconClassBuilder()({
              collapsed,
              active: isActive,
            })}
          />
          <span className="truncate hover:text-clip">{children}</span>
        </>
      )}
    </NavLink>
  )

  return (
    <If key={path} condition={collapsed} fallback={navLink}>
      <Tooltip>
        <TooltipTrigger>{navLink}</TooltipTrigger>
        <TooltipContent side="right" sideOffset={4}>
          {children}
        </TooltipContent>
      </Tooltip>
    </If>
  )
}
